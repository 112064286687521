/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconCalendarCheck, IconClockHour5, IconPointFilled } from '@tabler/icons-react'
import React from 'react';
import upcomingAppointmentsData from './upcomingAppointment.json';
import { format } from 'date-fns';

const UpcomingAppointments = () => {
  const [btnActive, setBtnActive] = React.useState(1);
  const handleClickAppointments = (e: any) => {
    setBtnActive(e);
  }
  return (
    <>
      <div
          className="tw-mt-6 tw-block tw-w-full tw-bg-white tw-border border-[#F2F2F2] tw-rounded-lg"
          >
            <div className='tw-flex tw-items-center tw-p-3 tw-justify-between'>
              <div className='tw-grid tw-gap-y-4'>
                <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#000] tw-mt-[8px]">
                    Upcoming Appointments
                </h5>
                <div className="tw-inline-flex tw-rounded-md" role="group">
                  <button type="button" className="tw-px-2.5 tw-py-2.5 tw-text-sm tw-font-semibold tw-text-[#182230] tw-bg-white tw-border tw-border-gray-200 tw-rounded-s-lg" 
                  onClick={() => handleClickAppointments(1)} style={{ backgroundColor: `${btnActive === 1 ? '#e1e3e796' : 'white'}`}}>
                      <div className='tw-flex tw-items-center'>
                        <IconPointFilled
                          className="tw-w-[18px]"
                          color="#17B26A"
                        /><span className='tw-text-[14px] tw-font-semibold tw-text-[#182230]'>Today, {format(new Date(), "DD MMM YYYY")}</span>
                      </div>
                  </button>
                  <button type="button" className="tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-[#182230] tw-bg-white tw-border tw-border-gray-200 tw-rounded-e-lg"
                  onClick={() => handleClickAppointments(2)} style={{ backgroundColor: `${btnActive === 2 ? '#e1e3e796' : 'white'}`}}>
                    <span className='tw-text-[14px] tw-font-semibold tw-text-[#182230]'>
                      Tomorrow
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            {upcomingAppointmentsData.map((appointment, index) => (
                <div className='tw-px-3 tw-py-1' key={index}>
                  <div className='tw-border border-[#F2F2F2] tw-rounded-lg tw-mt-2'>
                    <div className='tw-flex tw-flex-row tw-p-3'>
                      <div className='tw-w-[100%] tw-gap-3 tw-flex tw-text-[#868686]'>
                        <img className="tw-w-12 tw-h-12 tw-rounded-full" src="./img/profile.png" alt="Rounded avatar" />
                        <div>
                          <span className='tw-text-[16px] tw-font-semibold tw-text-[#101828]'>{appointment.name}</span>
                          <h5 className="tw-text-[14px] tw-font-normal tw-tracking-tight tw-text-[#475467]">
                              {appointment.gender + ', ' + appointment.age}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <span className="tw-bg-[#EAF6F7] tw-text-[#3CA5A9] tw-text-xs tw-font-medium  tw-px-2.5 tw-py-1 tw-rounded-full tw-border tw-border-[#A6DADC]">Confimed</span>
                      </div>
                    </div>
                    <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                    <div className='tw-p-3'>
                      <button className='tw-border tw-border-[#D0D5DD] tw-px-2 tw-py-0.5 tw-rounded-lg tw-font-medium tw-text-[#344054] tw-text-[12px]'>{appointment.status}</button>
                      <div className='tw-flex tw-justify-between tw-mt-3'>
                        <div className='tw-flex tw-items-start'>
                          <IconCalendarCheck className='tw-mr-2' color="#3CA5A9" size={20}/>
                          <span className='tw-text-[14px] tw-text-[#364152]'>26 Jun 2024</span>
                        </div>
                        <div className='tw-flex tw-items-start'>
                          <IconClockHour5 className='tw-mr-2' color="#3CA5A9" size={20}/>
                          <span className='tw-text-[14px] tw-text-[#364152]'>09:00 - 9:45 am</span>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
        </div>
    </>
  )
}

export default UpcomingAppointments
