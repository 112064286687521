/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Select } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

interface ServiceInfoProps {
  formData: any;
  handleOnSelect: (key: string, value: any) => void;
}

const ServiceInfo: React.FC<ServiceInfoProps> = (props: ServiceInfoProps) => {
  const medplum = useMedplum();
  const [services, setServices] = useState([]);
  const [servicesResponse, setServicesResponse] = useState([]);
  const [providerGroup, setProviderGroup] = useState([]);
  const [provider, setProvider] = useState([]);

  useEffect(() => {
    getHealthService();
    //getProvider();
  }, []);

  const getHealthService = () => {
    medplum
      .search('HealthcareService')
      .then((res: any) => {
        setServicesResponse(res?.entry);
        setServices(
          res?.entry
            .map((entry: any) => ({
              value: entry.resource?.name,
              label: entry?.resource?.name,
            }))
            .reduce((acc: any, current: any) => {
              const x = acc.find((item: any) => item.value === current.value);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, [])
        );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleServiceChange = (e: any) => {
    //filter provider based on selected service name
    const selectedService: any = servicesResponse.filter((service: any) => service.resource.name === e);

    setProviderGroup(
      selectedService.map((entry: any) => ({
        value: entry.resource?.providedBy.reference?.split('/')[1],
        label: entry.resource?.providedBy.display,
      }))
    );
    props.handleOnSelect('service', e);
  };

  const handleProviderChange = (e: any) => {
    medplum
      .search('PractitionerRole', {
        organization: `Organization/${e}`,
      })
      .then((res: any) => {
        console.log(res);
        setProvider(
          res?.entry.map((entry: any) => ({
            value: entry.resource?.practitioner.reference?.split('/')[1],
            label: entry?.resource?.practitioner.display,
          }))
        );
      })
      .catch((error: any) => {
        console.log('error', error);
      });
    props.handleOnSelect('providerGroup', e);
  };
  return (
    <>
      <h3 className="tw-text-lg tw-font-semibold tw-text-[#101828]">Service Information</h3>
      <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
        <div className="tw-w-full">
          <Select
            label={<span className="tw-text-[#475467]">Service</span>}
            placeholder="Select Service"
            data={services}
            value={props.formData.service}
            name="service"
            rightSection={<IconChevronDown size="16px" />}
            onChange={handleServiceChange}
            required
          />
        </div>
      </div>
      <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
        <div className="tw-w-[48%]">
          <Select
            label={<span className="tw-text-[#475467]">Provider Group</span>}
            data={providerGroup}
            placeholder="Provider Group"
            value={props.formData?.providerGroup}
            name="providerGroup"
            onChange={handleProviderChange}
          />
        </div>
        <div className="tw-w-[48%]">
          <Select
            label={<span className="tw-text-[#475467]">Provider</span>}
            data={provider}
            placeholder="Provider"
            value={props.formData?.provider}
            name="provider"
            onChange={(event) => props.handleOnSelect('provider', event)}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceInfo;
