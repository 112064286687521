/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { getSlots } from '../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import {format} from 'date-fns';

interface SlotsProps {
    onSelectSlot: (slot: any) => void;
    serviceId: string;
    startDate: string;
    slotError: boolean;
}

interface Slot {
    id: string;
    time: string;
    startTime: string;
    endTime: string;
}

const Slots: React.FC<SlotsProps> = (props: SlotsProps) => {

    const [activeSlot, setActiveSlot] = useState<string>('')
    const medplum = useMedplum();
    const [slots, setSlots] = useState<Slot[]>([]);
    const [isSlotsEmpty, setIsSlotsEmpty] = useState<boolean>(false);
    const onClickSlot = (slot: Slot) => {
        setActiveSlot(slot?.id);
        props.onSelectSlot(slot);
    }

    useEffect(() => {
        if(props.serviceId && props.startDate) {
            getSlotsList();
        }
    }, [props.serviceId, props.startDate]);
    
    const getSlotsList = () => {

        getSlots(medplum, props.serviceId, format(props.startDate, 'YYYY-MM-DD')).then((res) => {
            if(res.data.entry.length === 0) {
                setIsSlotsEmpty(true);
                setSlots([]);
            } else {
                setIsSlotsEmpty(false);
                setSlots(res.data.entry.map((slot: any) => {
                return {
                    id: slot.resource.id,
                    time: new Date(slot.resource.start).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                    startTime: slot.resource.start,
                    endTime: slot.resource.end
                }
                }))
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  return (
    <>
      {slots.length > 0 && (
        <>
            <label className='tw-text-[#475467] tw-font-semibold'>Slots</label>
            <div className='slots_container'>
                {slots.map((slot) => {
                    const isActiveSlot = activeSlot === slot.id;
                    const slotStyle = {
                        backgroundColor: isActiveSlot ? '#4DB5BA' : 'white',
                        color: isActiveSlot ? 'white' : '#333',
                        cursor: 'pointer'
                    };
                    return (
                        <div key={slot.id} onClick={() => onClickSlot(slot)} className='slots_btn tw-w-1/5' style={slotStyle}>
                            <span>{slot.time}</span>
                        </div>
                    );
                })}
                {props?.slotError && (<span className='tw-font-medium tw-text-[red]'>Please select slot</span>)}
            </div>
        </>
      )} 
      {isSlotsEmpty && (
        <>
            <label className='tw-text-[#475467] tw-font-semibold'>Slots</label>
            <div className='tw-text-center tw-font-medium tw-mt-10 tw-mb-10 tw-text-[18px]'>No slots available.</div>
        </>
      )}
    </>
  )
}

export default Slots
