import { IconCirclePlusFilled } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import {format} from 'date-fns';
import Appointments from '../../Appointments/Appointments';
import { useNavigate } from 'react-router-dom';

interface AppointmentDetailsProps {
  appointmentsResource: any;
}

const AppointmentDetails: React.FC<AppointmentDetailsProps> = (props: AppointmentDetailsProps) => {
  const navigate = useNavigate();
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  const [prviousAppointments, setPrviousAppointments] = useState<any>([]);
  const [nextAppointment, setNextAppointment] = useState<any>([]);
  const { appointmentsResource } = props;

  useEffect(() => {
    if (appointmentsResource) {
      const now = new Date();

      //get the previous appointment
      const filteredAppointments = appointmentsResource?.filter((appointment: { resource: { status: string; start: string | number | Date; }; }) => 
        appointment.resource.status !== 'cancelled' && 
        new Date(appointment.resource.start).getTime() < now.getTime()
      );
      setPrviousAppointments(filteredAppointments);
  
      //get the next appointment
      const filterNextAppointment = appointmentsResource?.filter((appointment: { resource: { status: string; start: string | number | Date; }; }) => 
        appointment.resource.status !== 'cancelled' && 
        new Date(appointment.resource.start).getTime() > now.getTime()
      );
      setNextAppointment(filterNextAppointment);
    }
  }, [appointmentsResource]);

  return (
    <>
      <div className="tw-mt-6 tw-block tw-pb-3 tw-bg-white tw-border border-[#E3E8EF] tw-rounded-md">
        <div className="tw-bg-[#F8FAFC] tw-p-4 tw-rounded-e-md tw-rounded-s-md">
          <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#344054]">Next Appointment</h5>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {nextAppointment?.length > 0 ? (
          nextAppointment?.map((appointment: any, index: number) => (
            <div className="tw-p-4" key={index}>
              <div className="tw-flex tw-mb-5">
                <div className="tw-w-1/2">
                  <p className="tw-font-semibold tw-text-[14px]">
                    {appointment?.resource?.start ? format(new Date(appointment?.resource?.start), 'ddd, DD MMMM YYYY, hh:mm a') : ''}
                  </p>
                  <p className="tw-font-medium tw-text-[14px] tw-text-[#4B5565] tw-mt-1">{appointment.resource.status}</p>
                </div>
                <div className="tw-w-1/2">
                  <p className="tw-font-medium tw-text-[14px] tw-text-[#4B5565]">
                    {appointment?.resource?.participant?.find((participant: { actor: { reference: string; }; }) => participant.actor?.reference?.startsWith('Practitioner'))?.actor?.display}
                  </p>
                  <button className="tw-border tw-border-[#D0D5DD] tw-rounded-lg tw-py-2 tw-px-4 tw-mt-1 tw-text-[#344054] tw-font-semibold" onClick={() => navigate('/Appointment')}>
                    Goto appointment
                  </button>
                </div>
              </div>
              <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  <button className="tw-inline-flex tw-items-center tw-me-2 tw-border tw-border-[#D0D5DD] tw-rounded-lg tw-py-2 tw-px-4 tw-mt-1 tw-bg-[#4DB5BA] tw-text-[#fff] tw-font-semibold tw-mt-3" onClick={() => setIsAppointment(true)}>
                <IconCirclePlusFilled className="tw-me-2" />
                Book a new appointment
              </button>
            </div>
          ))
        ) : (
          <div className="tw-p-4">
            <p className="tw-font-semibold tw-text-[15px] tw-text-center">No next appointments</p>
          </div>
        )}
      </div>

      {/* Prvious Appoitment block */}

      <div className="tw-mt-6 tw-block tw-pb-3 tw-bg-white tw-border border-[#E3E8EF] tw-rounded-md">
        <div className="tw-bg-[#F8FAFC] tw-p-4 tw-rounded-e-md tw-rounded-s-md">
          <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#344054]">Previous Appointment</h5>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <div className='tw-h-[250px] scrollable_div'>
          {prviousAppointments?.length > 0 ? prviousAppointments?.map((appointment: any, index: number) => (
            <div className="tw-p-4" key={index}>
              <div className="tw-flex tw-mb-5">
                <div className="tw-w-3/5">
                  <p className="tw-font-semibold tw-text-[14px]">
                    {appointment?.resource?.start ? format(new Date(appointment?.resource?.start), 'ddd, DD MMMM YYYY, hh:mm a') : ''}
                  </p>
                  <p className="tw-font-medium tw-text-[14px] tw-text-[#4B5565] tw-mt-1">{appointment.resource.status}</p>
                </div>
                <div className="tw-w-2/5">
                  <p className="tw-font-medium tw-text-[14px] tw-text-[#4B5565]">
                    {appointment?.resource?.participant?.find((participant: { actor: { reference: string; }; }) => participant.actor?.reference?.startsWith('Practitioner'))?.actor?.display}
                  </p>
                  <button className="tw-border tw-border-[#D0D5DD] tw-rounded-lg tw-py-2 tw-px-4 tw-mt-1 tw-text-[#344054] tw-font-semibold" onClick={() => navigate('/Appointment')}>
                    Goto appointment
                  </button>
                </div>
              </div>
              <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            </div>
          )) : (
            <div className="tw-p-4">
              <p className="tw-font-semibold tw-text-[15px] tw-text-center">No previous appointments</p>
            </div>
          )}
        </div>
      </div>

      {isAppointment && (
        <Appointments opened={isAppointment} close={() => setIsAppointment(false)} appointmentId="" />
      )}
    </>
  );
};

export default AppointmentDetails;
