/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import { Center, Checkbox, Container, Divider, Loader, Menu, Text, UnstyledButton } from '@mantine/core';
import { MouseEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_SEARCH_COUNT, Filter, SearchRequest, formatSearchQuery } from '@medplum/core';
import { Bundle, OperationOutcome, Resource, ResourceType, SearchParameter } from '@medplum/fhirtypes';
// import { Container } from '../../../react/src/Container/Container';
import { useMedplum } from '@medplum/react-hooks';
import { SearchFieldEditor } from '../../../react/src/SearchFieldEditor/SearchFieldEditor';
import { SearchFilterEditor } from '../../../react/src/SearchFilterEditor/SearchFilterEditor';
import { SearchFilterValueDialog } from '../../../react/src/SearchFilterValueDialog/SearchFilterValueDialog';
import { SearchPopupMenu } from '../../../react/src/SearchPopupMenu/SearchPopupMenu';
import { getFieldDefinitions } from '../../../react/src/SearchControl/SearchControlField';
import { addFilter, buildFieldNameString, renderValue, setPage } from '../../../react/src/SearchControl/SearchUtils';
import { listingPage } from '../utils/CustomAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IconAdjustmentsHorizontal,
  IconCalendar,
  IconPencil,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import Appointments from './Appointments/Appointments';
import AddPatient from './AddPatient/AddPatient';
import FilterTags from '../components/FilterTags';
import SearchAndFilter from '../components/SearchAndFilter';

export class SearchChangeEvent extends Event {
  readonly definition: SearchRequest;

  constructor(definition: SearchRequest) {
    super('change');
    this.definition = definition;
  }
}

export class SearchLoadEvent extends Event {
  readonly response: Bundle;

  constructor(response: Bundle) {
    super('load');
    this.response = response;
  }
}

export class SearchClickEvent extends Event {
  readonly resource: Resource;
  readonly browserEvent: MouseEvent;

  constructor(resource: Resource, browserEvent: MouseEvent) {
    super('click');
    this.resource = resource;
    this.browserEvent = browserEvent;
  }
}

export interface SearchControlProps {
  readonly search: any;
  readonly checkboxesEnabled?: boolean;
  readonly hideToolbar?: boolean;
  readonly hideFilters?: boolean;
  readonly onLoad?: (e: SearchLoadEvent) => void;
  readonly onChange?: (e: SearchChangeEvent) => void;
  readonly onClick?: (e: SearchClickEvent) => void;
  readonly onNew?: () => void;
}

interface SearchControlState {
  readonly searchResponse?: Bundle;
  readonly selected: { [id: string]: boolean };
  readonly fieldEditorVisible: boolean;
  readonly filterEditorVisible: boolean;
  readonly filterDialogVisible: boolean;
  readonly exportDialogVisible: boolean;
  readonly filterDialogFilter?: Filter;
  readonly filterDialogSearchParam?: SearchParameter;
}

/**
 * The SearchControl component represents the embeddable search table control.
 * It includes the table, rows, headers, sorting, etc.
 * It does not include the field editor, filter editor, pagination buttons.
 * @param props - The SearchControl React props.
 * @returns The SearchControl React node.
 */
export function SearchControl(props: SearchControlProps): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const { search, onLoad } = props;
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  const [isResourceId, setIsResourceId] = useState<string>('');
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [patientData, setPatientData] = useState<any>();
  const [searchQuery, setSearchQuery] = useState('');
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});
  const [activeFilterTab, setActiveFilterTab] = useState<string>('');

  const [state, setState] = useState<SearchControlState>({
    selected: {},
    fieldEditorVisible: false,
    filterEditorVisible: false,
    exportDialogVisible: false,
    filterDialogVisible: false,
  });

  const stateRef = useRef<SearchControlState>(state);
  stateRef.current = state;

  const total = search.total ?? 'accurate';

  const loadResults = useCallback(
    (options?: RequestInit) => {
      setOutcome(undefined);

      medplum
        .search(
          search.resourceType as ResourceType,
          formatSearchQuery({ ...search, total, fields: undefined }),
          options
        )
        .then((response) => {
          setState({ ...stateRef.current, searchResponse: response });
          if (onLoad) {
            onLoad(new SearchLoadEvent(response));
          }
        })
        .catch((reason) => {
          setState({ ...stateRef.current, searchResponse: undefined });
          setOutcome(reason);
        });
    },
    [medplum, search, total, onLoad]
  );

  //calling custom API to get the patient session details
  const loadBrandListResults = useCallback(() => {
    const apiUrls = {
      '/Appointment': 'get-appointment-list',
      '/WaitingList': 'get-appointment-list',
      '/Patient': 'get-patient-list',
    } as Record<string, string>;

    const apiUrl = apiUrls[location.pathname] || '';
    setOutcome(undefined);
    listingPage(medplum, apiUrl, search.count, search.offset, search)
      .then((response) => {
        setState({ ...stateRef.current, searchResponse: response?.data });
        if (onLoad) {
          onLoad(new SearchLoadEvent(response?.data));
        }
      })
      .catch((reason) => {
        setState({ ...stateRef.current, searchResponse: undefined });
        setOutcome(reason);
      });
  }, [medplum, search, onLoad]);

  useEffect(() => {
    const resourceTypes = ['Appointment', 'WaitingList', 'Patient'];
    resourceTypes.includes(props.search.resourceType) ? loadBrandListResults() : loadResults();
  }, [loadResults]);

  /**
   * Emits a change event to the optional change listener.
   * @param newSearch - The new search definition.
   */
  function emitSearchChange(newSearch: SearchRequest): void {
    if (props.onChange) {
      props.onChange(new SearchChangeEvent(newSearch));
    }
  }

  useEffect(() => {
    setSchemaLoaded(false);
    if (props.search.resourceType === 'WaitingList') {
      props.search.resourceType = 'Appointment';
    }
    medplum
      .requestSchema(props.search.resourceType as ResourceType)
      .then(() => {
        setSchemaLoaded(true);
      })
      .catch(console.error);
  }, [medplum, props.search.resourceType]);

  if (!schemaLoaded) {
    return (
      <Center style={{ width: '100%', height: '100%' }}>
        <Loader />
      </Center>
    );
  }

  const fields = getFieldDefinitions(search);
  const resourceType = search.resourceType;
  const lastResult = state.searchResponse;
  const totalResults = lastResult ? getTotal(search, lastResult) : 0;
  const entries = lastResult?.entry;
  const resources = entries?.map((e) => e.resource);

  fields?.map((field: any) => {
    if (field.name === 'title') {
      field['searchParams'] = [
        {
          resourceType: 'SearchParameter',
          code: 'title',
          name: 'title',
          base: ['Resource'],
          type: 'string',
          expression: 'Resource.qualification[0].code.coding[0].code',
        },
      ];
    } else if (field.name === 'email') {
      field['searchParams'] = [
        {
          resourceType: 'SearchParameter',
          code: 'email',
          name: 'email',
          base: ['Resource'],
          type: 'string',
          expression: "Resource.telecom.where(system='email').value",
        },
      ];
    }
  });

  const filterResources = (resources: any[]) => {
    return resources.filter((resource) => {
      // Filter by searchQuery
      const searchableFields = fields.map((field) => field.name);
      const matchesSearchQuery =
        !searchQuery ||
        searchableFields.some((fieldName) => {
          let value;
          if (fieldName === 'patient' || fieldName === 'name') {
            value = resource?.name?.[0]?.given?.[0]
              ? resource?.name?.[0]?.given?.[0] + ' ' + resource?.name?.[0]?.family
              : resource?.patient?.name;
          } else if (fieldName === 'phone') {
            value = resource?.telecom?.find((telecom: { system: string }) => telecom.system === 'phone')?.value;
          } else if (fieldName === 'practitioner') {
            value = resource?.practitioner?.name;
          } else {
            value = resource[fieldName];
          }

          return value?.toString().toLowerCase().includes(searchQuery.toLowerCase());
        });

      // Filter by activeFilterTab

      if (location.pathname.split('/')[1] !== 'Patient') {
        const matchesFilterTab = !activeFilterTab || resource.status === activeFilterTab;
        return matchesSearchQuery && matchesFilterTab;
      }

      // Return true if the resource matches both filters
      return matchesSearchQuery;
    });
  };

  // Function to handle "check all" checkbox
  const handleCheckAll = () => {
    const newSelectedItems: Record<string, boolean> = {};
    if (!isCheckedAll) {
      filterResources(resources || []).forEach((resource) => {
        newSelectedItems[resource.id] = true;
      });
    }
    setSelectedItems(newSelectedItems);
    setIsCheckedAll(!isCheckedAll);
  };

  // Function to handle individual checkbox
  const handleCheckItem = (e: any, resourceId: any) => {
    e.stopPropagation(); // Prevent row click event
    setSelectedItems((prev) => {
      const newSelectedItems: Record<string, boolean> = { ...prev, [resourceId]: !prev[resourceId] };
      // Update isCheckedAll based on whether all items are now checked
      const allChecked = filterResources(resources || []).every((resource) => newSelectedItems[resource.id]);
      setIsCheckedAll(allChecked);
      return newSelectedItems;
    });
  };

  return (
    <div data-testid="search-control" className="tw-py-8 tw-pr-6">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
        <div>
          <div className="tw-flex tw-items-center tw-justify-between">
            <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-mr-2">{location.pathname.split('/')[1] === 'WaitingList' ? 'Waiting List' : location.pathname.split('/')[1]}{['Appointment', 'Patient'].includes(location.pathname.split('/')[1]) && 's'}</h1>
            <span className="tw-py-[2px] tw-px-[6px] tw-border-[1px] tw-border-[#D0D5DD] tw-rounded-md">
              {totalResults} {location.pathname.split('/')[1] === 'WaitingList' ? 'Waiting List' : location.pathname.split('/')[1]}{['Appointment', 'Patient'].includes(location.pathname.split('/')[1]) && 's'}
            </span>
          </div>
          <p className="tw-text-sm tw-text-gray-500">Keep track of all {location.pathname.split('/')[1] === 'WaitingList' ? 'Waiting List' : location.pathname.split('/')[1]}{['Appointment', 'Patient'].includes(location.pathname.split('/')[1]) && 's'}</p>
        </div>
        <div className="tw-flex tw-gap-2">
          {location.pathname.split('/')[1] === 'Appointment' && (
            <button
              className="tw-inline-flex tw-items-center tw-me-2 tw-border tw-border-[#D0D5DD] tw-rounded-lg tw-py-2 tw-px-2 tw-bg-[#F9FAFB] tw-text-[#202939] tw-font-semibold"
            >
              <IconCalendar className="tw-font-semibold tw-me-2" size={20} />
              Calendar View
            </button>
          )}


          {props.onNew && (
            <button
              className="tw-inline-flex tw-items-center tw-me-2 tw-border tw-border-[#3CA5A9] tw-rounded-lg tw-py-2 tw-px-2 tw-bg-[#4DB5BA] tw-text-[#fff] tw-font-semibold"
              onClick={props.onNew}
            >
              <IconPlus className="tw-font-semibold tw-me-2" size={20} />
              Add New {location.pathname.split('/')[1] === 'WaitingList' ? 'Waiting List' : location.pathname.split('/')[1]}
            </button>
          )}
        </div>
      </div>

      <Divider className="tw-bg-[#EAECF0] tw-mb-4" />

      <div className="tw-flex tw-w-full">
        {/* Tags */}

        {location.pathname.split('/')[1] !== 'Patient' && (
          <FilterTags activeFilterTab={activeFilterTab} setActiveFilterTab={setActiveFilterTab} />
        )}

        {/* Search Input */}
        <SearchAndFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} state={state} setState={setState} />
      </div>
      <Divider className="tw-bg-[#EAECF0]" />

      {/* Table */}
      <div className="tw-bg-white tw-py-8 ">
        <table className=" tw-w-full tw-text-sm tw-text-left listing_table">
          <thead className="tw-text-xs tw-text-gray-700 tw-bg-gray-50 tw-border-b-[1px] tw-border-[#EAECF0]">
            <tr className="tw-py-3 tw-pl-[1.5rem]">
              <th className="tw-py-3 tw-pl-[1.5rem]">
                <Checkbox
                  onChange={handleCheckAll}
                  checked={isCheckedAll}
                  className="tw-rounded-lg tw-accent-[#2AAAAF]"
                />
              </th>
              {fields.map((field, index) => (
                <th key={index} className="tw-py-3 tw-px-[0.5rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                  <div className="tw-flex tw-justify-between tw-items-center tw-w-max ">
                    {buildFieldNameString(
                      field.name === 'start' ? 'Start Date' : field.name === 'end' ? 'End Date' : field.name
                    )}

                    <div>
                      <Menu shadow="md" width={240} position="bottom-end">
                        <Menu.Target>
                          <UnstyledButton className="tw-p-2">
                            <div>
                              <IconAdjustmentsHorizontal size={14} stroke={1.5} />
                            </div>
                          </UnstyledButton>
                        </Menu.Target>
                        <SearchPopupMenu
                          search={props.search}
                          searchParams={field.searchParams}
                          onPrompt={(searchParam, filter) => {
                            setState({
                              ...stateRef.current,
                              filterDialogVisible: true,
                              filterDialogSearchParam: searchParam,
                              filterDialogFilter: filter,
                            });
                          }}
                          onChange={(result) => {
                            emitSearchChange(result);
                          }}
                        />
                      </Menu>
                    </div>
                  </div>
                </th>
              ))}
              <th className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium tw-text-sm">Actions</th>
            </tr>
          </thead>

          <tbody>
            {filterResources(resources || [])?.map((resource: any) => (
              <tr key={resource.id} className="tw-border-b hover:tw-bg-gray-50 tw-cursor-pointer">
                <td className="tw-pl-[1.5rem] tw-py-6">
                  <Checkbox
                    checked={selectedItems[resource.id] || false}
                    onChange={(e) => handleCheckItem(e, resource.id)}
                    className="tw-rounded-lg tw-w-4 tw-accent-[#2AAAAF]"
                  />
                </td>
                {fields.map((field, index) => (
                  <td
                    key={index}
                    className="tw-px-[0.5rem] tw-py-6 tw-font-normal tw-text-[#475467]"
                    onClick={() => {
                      setIsResourceId(resource.id);
                      if (location.pathname === '/Appointment') {
                        setIsAppointment(true);
                      } else if (location.pathname === '/Patient') {
                        navigate(`/${resourceType}/${resource.id}/details`);
                      } else {
                        navigate(`/${resourceType}/${resource.id}/edit`, {
                          state: {
                            adminData: resource?.admin,
                            brands: resource?.brand,
                            userId: resource?.userId,
                            role: resource?.role || resource?.roles,
                          },
                        });
                      }
                    }}
                  >
                    {field.name === 'patient' || field.name === 'name' ? (
                      <span key={index} className="tw-flex tw-w-max">
                        <div className="tw-flex tw-items-center">
                            <div className="tw-font-medium tw-text-[#101828]">
                              {resource?.name?.[0]?.given?.[0]
                                ? resource?.name?.[0]?.given?.[0] + ' ' + resource?.name?.[0]?.family
                                : resource?.patient?.name}
                            </div>
                        </div>
                      </span>
                    ) : field.name === 'phone' ? (
                      resource?.telecom?.find((telecom: { system: string }) => telecom.system === 'phone')?.value
                    ) : (
                      renderValue(resource, field)
                    )}
                  </td>
                ))}
                <td className="tw-px-6 tw-py-4">
                  <div className="tw-flex tw-gap-4">
                    <IconTrash
                      size={22}
                      stroke={'1.67px'}
                      className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                    />
                    <IconPencil
                      size={22}
                      stroke={'1.67px'}
                      className="tw-text-[#475467] hover:tw-text-blue-500 tw-cursor-pointer"
                      onClick={() => {
                        setIsResourceId(resource.id);
                        if (location.pathname === '/Appointment') {
                          setIsAppointment(true);
                        } else if (location.pathname === '/Patient') {
                          setIsAddPatient(true);
                          setPatientData(resource);
                        } else {
                          navigate(`/${resourceType}/${resource.id}/edit`, {
                            state: {
                              adminData: resource?.admin,
                              brands: resource?.brand,
                              userId: resource?.userId,
                              role: resource?.role || resource?.roles,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Divider className="tw-bg-[#EAECF0]" />

      {filterResources(resources || []).length === 0 && (
        <Container>
          <Center style={{ height: 150 }}>
            <Text size="xl" c="dimmed">
              No results Found
            </Text>
          </Center>
        </Container>
      )}

      {/* Pagination */}
      {lastResult && (
        <div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
          <p className="tw-text-sm tw-font-medium tw-text-[#344054]">
            Page {getPage(search)} of {getTotalPages(search, lastResult)}
          </p>
          <div className="tw-flex tw-gap-2">
            <button
              className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${getPage(search) <= 1 ? 'tw-opacity-50' : ' tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'} tw-border tw-border-[#D0D5DD] tw-rounded-lg `}
              onClick={() => {
                const newPage = Math.max(1, getPage(search) - 1);
                emitSearchChange(setPage(search, newPage));
              }}
              disabled={getPage(search) <= 1}
              // style={{ visibility: getPage(search) > 1 ? 'visible' : 'hidden' }}
            >
              Previous
            </button>
            <button
              className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${getPage(search) >= getTotalPages(search, lastResult) ? 'tw-opacity-50' : ' tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'} tw-border tw-border-[#D0D5DD] tw-rounded-lg`}
              onClick={() => {
                const newPage = Math.min(getTotalPages(search, lastResult), getPage(search) + 1);
                emitSearchChange(setPage(search, newPage));
              }}
              disabled={getPage(search) >= getTotalPages(search, lastResult)}
              // style={{ visibility: getPage(search) < getTotalPages(search, lastResult) ? 'visible' : 'hidden' }}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {outcome && (
        <div data-testid="search-error">
          <pre style={{ textAlign: 'left' }}>{JSON.stringify(outcome, undefined, 2)}</pre>
        </div>
      )}
      <SearchFieldEditor
        search={props.search}
        visible={stateRef.current.fieldEditorVisible}
        onOk={(result) => {
          emitSearchChange(result);
          setState({
            ...stateRef.current,
            fieldEditorVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            fieldEditorVisible: false,
          });
        }}
      />
      <SearchFilterEditor
        search={props.search}
        visible={stateRef.current.filterEditorVisible}
        onOk={(result) => {
          emitSearchChange(result);
          setState({
            ...stateRef.current,
            filterEditorVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            filterEditorVisible: false,
          });
        }}
      />
      <SearchFilterValueDialog
        key={state.filterDialogSearchParam?.code}
        visible={stateRef.current.filterDialogVisible}
        title={state.filterDialogSearchParam?.code ? buildFieldNameString(state.filterDialogSearchParam.code) : ''}
        resourceType={resourceType}
        searchParam={state.filterDialogSearchParam}
        filter={state.filterDialogFilter}
        defaultValue=""
        onOk={(filter) => {
          emitSearchChange(addFilter(props.search, filter.code, filter.operator, filter.value));
          setState({
            ...stateRef.current,
            filterDialogVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            filterDialogVisible: false,
          });
        }}
      />
      {isAppointment && (
        <Appointments opened={isAppointment} close={() => setIsAppointment(false)} appointmentId={isResourceId} />
      )}
      {isAddPatient && (
        <AddPatient
          opened={isAddPatient}
          patientClose={() => setIsAddPatient(false)}
          patientId={isResourceId}
          patientData={patientData}
        />
      )}
    </div>
  );
}

export const MemoizedSearchControl = memo(SearchControl);

function getPage(search: SearchRequest): number {
  return Math.floor((search.offset ?? 0) / (search.count ?? DEFAULT_SEARCH_COUNT)) + 1;
}

function getTotalPages(search: SearchRequest, lastResult: Bundle): number {
  const pageSize = search.count ?? DEFAULT_SEARCH_COUNT;
  const total = getTotal(search, lastResult);
  return Math.ceil(total / pageSize);
}

function getTotal(search: SearchRequest, lastResult: Bundle): number {
  let total = lastResult.total;
  if (total === undefined) {
    // If the total is not specified, then we have to estimate it
    total =
      (search.offset ?? 0) +
      (lastResult.entry?.length ?? 0) +
      (lastResult.link?.some((l) => l.relation === 'next') ? 1 : 0);
  }
  return total;
}
