/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Modal } from '@mantine/core';
import React from 'react';

interface ConfirmBoxProps {
    opened: boolean;
    close: () => void;
    onConfirm: (cancelReason: string) => void;
}

const ConfirmBox: React.FC<ConfirmBoxProps> = (props: ConfirmBoxProps) => {
    const [cancelReason, setCancelReason] = React.useState<string>('');
    const handleInputChange = (e: any) => {
        setCancelReason(e.target.value);
    }
  return (
    <>
      <Modal opened={props?.opened} size="sm" onClose={props?.close} centered>
        <form>
            <div className="tw-w-full tw-mb-4 md:tw-mb-0 tw-mt-3">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                    Please provide a reason for canceling the appointment
                </label>
                <textarea
                    className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-rounded-lg tw-border tw-border-gray-300"
                    placeholder="Enter cancelation reason..."
                    name="cancelReason"
                    required
                    onChange={handleInputChange}
                    value={cancelReason}
                ></textarea>
            </div>
            <div className="tw-flex tw-mt-4">
                <button type="button" className="tw-w-1/2 tw-text-[black] tw-bg-white tw-font-semibold tw-rounded-lg tw-text-[15px] tw-px-5 tw-py-2 tw-me-2 tw-border tw-border-[#D0D5DD]" >Cancel</button>
                <button
                    className="tw-w-1/2 tw-text-white tw-bg-[#4DB5BA] tw-px-5 tw-py-2 tw-rounded-lg tw-text-sm tw-font-medium"
                    type='button'
                    onClick={() => props.onConfirm(cancelReason)}
                >
                    Confirm
                </button>
            </div>
        </form>
      </Modal>
    </>
  )
}

export default ConfirmBox
