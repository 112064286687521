/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Select, TextInput } from '@mantine/core';
import React from 'react';
import { USA_STATES } from '../utils/constant';

interface ContactInfoProps {
  formData: any;
  handleInputChange: (e: any) => void;
  handleOnSelect: (key: string, value: any) => void;
}

const ContactInfo: React.FC<ContactInfoProps> = (props: ContactInfoProps) => {

  return (
    <>
      <h3 className="tw-text-lg tw-mt-6 tw-font-semibold tw-text-[#101828]">Contact Information</h3>
      <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
        <div className="tw-w-[48%]">
          <TextInput
            type="number"
            value={props.formData?.mobile}
            name="mobile"
            placeholder="Mobile Number"
            label={<span className="tw-text-[#475467]">Mobile Number</span>}
            onChange={props.handleInputChange}
            required
            maxLength={10}
          />
        </div>
        <div className="tw-w-[48%]">
          <TextInput
            type="email"
            placeholder="Email"
            name="email"
            label={<span className="tw-text-[#475467]">Email</span>}
            value={props.formData?.email}
            required
            onChange={props.handleInputChange}
          />
        </div>
      </div>
      <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
        <div className="tw-w-[48%]">
          <TextInput
            placeholder="Street Address"
            name="street"
            label={<span className="tw-text-[#475467]">Street Address</span>}
            value={props.formData?.street}
            required
            onChange={props.handleInputChange}
          />
        </div>
        <div className="tw-w-[48%]">
          <TextInput
            placeholder="City"
            name="city"
            label={<span className="tw-text-[#475467]">City</span>}
            value={props.formData?.city}
            required
            onChange={props.handleInputChange}
          />
        </div>
      </div>
      <div className="tw-flex tw-items-end tw-mt-5 tw-gap-5">
        <div className="tw-w-[48%]">
          <Select
            data={USA_STATES}
            placeholder="State"
            value={props.formData?.state}
            name="state"
            label={<span className="tw-text-[#475467]">State</span>}
            searchable
            onChange={(event) => props.handleOnSelect('state', event)}
          />
        </div>
        <div className="tw-w-[48%]">
          <TextInput
            placeholder="Zip Code"
            name="postalCode"
            label={<span className="tw-text-[#475467]">Zip Code</span>}
            value={props.formData?.postalCode}
            required
            onChange={props.handleInputChange}
            maxLength={6}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ContactInfo);
