/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box } from '@mantine/core';
import React from 'react'
import FeaturedCards from './FeaturedCards';
import Provider from './ProviderDashboard/Provider';
import PrescriptionsCard from './ProviderDashboard/PrescriptionsCard'
import Tasks from './ProviderDashboard/Tasks'
import PatientGraph from './ProviderDashboard/PatientGraph';
import UpcomingAppointments from './UpcomingAppointments';
import { Header } from '../components/Header';
import { useMedplum } from '@medplum/react';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';

const Index = () => {
  const medplum = useMedplum();
  const [btnActive, setBtnActive] = React.useState(0);
  const timeFilterData = [
    { id: 0, label: 'All' },
    { id: 1, label: '12 months' },
    { id: 2, label: '30 days' },
    { id: 3, label: '7 days' },
    { id: 4, label: '24 hours' },
  ]

  const handleTimeFrame = (e: any) => {
    console.log(e);
    setBtnActive(e.id);
  }
  return (
    <>
        <Header />
        <Box px="lg" py="sm" className='dashboard-container'>
            <div className="tw-block tw-w-full tw-p-2 xs:tw-p-0 tw-bg-white">
                <h5 className="tw-text-[25px] xs:tw-text-[18px] tw-font-semibold tw-tracking-tight tw-text-gray-900">Welcome back, {medplum.getProfile()?.name?.[0].given?.[0] + ' ' + medplum.getProfile()?.name?.[0].family}</h5>
                <div className='tw-flex tw-justify-between tw-mt-6 filter-container'>
                  <div className="tw-inline-flex tw-rounded-md time-filter" role="group">
                    {timeFilterData.map((data) => (
                      <button key={data.id} type="button" className="tw-px-4 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-[#344054] tw-text-[14px] tw-bg-white tw-border tw-border-[#D0D5DD]"
                      onClick={() => handleTimeFrame(data)} style={{ backgroundColor: `${btnActive === data.id ? '#e1e3e796' : 'white'}`}}>
                        {data.label}
                      </button>
                    ))}
                  </div>
                  <div className='tw-flex tw-gap-3 date'>
                      <DatePickerInput
                        leftSection={<IconCalendar size={20} color='#344054'/>}
                        leftSectionPointerEvents="none"
                        placeholder="Select dates"
                      />
                  </div>
                </div>
                <FeaturedCards />
                <div className='tw-flex tw-gap-5 xs:tw-gap-0 xs:tw-flex-wrap sm:tw-flex-wrap md:tw-flex-wrap'>
                  <div className="tw-w-[70%] xs:tw-w-[100%] sm:tw-w-[100%] md:tw-w-[100%]">
                        <Provider />
                        <PatientGraph />
                        <div className='tw-flex tw-gap-5 xs:tw-gap-0 xs:tw-flex-wrap sm:tw-flex-wrap md:tw-flex-wrap'>
                          <PrescriptionsCard />
                          <Tasks />
                        </div>
                  </div>
                  <div className="tw-w-[35%] xs:tw-w-[100%] sm:tw-w-[100%] md:tw-w-[100%]">
                      <UpcomingAppointments />
                  </div>
                </div>
            </div>
        </Box>
    </>
  )
}

export default Index;
