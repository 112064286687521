import { Group, AppShell as MantineAppShell, Menu,  UnstyledButton } from '@mantine/core';
import { IconBell, IconSettings, IconUser } from '@tabler/icons-react';
import cx from 'clsx';
import { useState } from 'react';
import classes from '../../../react/src/AppShell/Header.module.css';
import { HeaderDropdown } from '../../../react/src/AppShell/HeaderDropdown';

export function Header(): JSX.Element {
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  return (
    <MantineAppShell.Header p={8} mt={10} style={{ border: 'none', position: 'relative' }}>
      <Group justify="end">
        <Group gap="lg" pr="sm">
          <div className='tw-flex tw-gap-4'>
            <button type="button" className="tw-relative tw-inline-flex tw-items-center tw-p-1 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg">
            <IconSettings color='#667085'/>
            </button>
            <button type="button" className="tw-relative tw-inline-flex tw-items-center tw-p-1 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg">
            <IconBell color='#667085'/>
            </button>
          </div>
          <Menu
            width={200}
            shadow="xl"
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            opened={userMenuOpened}
            onClose={() => setUserMenuOpened(false)}
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                onClick={() => setUserMenuOpened((o) => !o)}
                 style={{ backgroundColor: '#EAF6F7', borderRadius: '50%', padding: '10px'}}
              >
                <Group gap={7}>
                  {/* <Avatar src="" alt="profile" m="auto" size="md"/> */}
                  <IconUser size={23} color="#4DB5BA" />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <HeaderDropdown version='' />
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </MantineAppShell.Header>
  );
}
