/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { IconCirclePlus } from '@tabler/icons-react';
import { Checkbox } from '@mantine/core';

const Tasks = () => {
  return (
    <>
      <div className="tw-mt-6 tw-block tw-w-full tw-p-2 tw-bg-white tw-border border-[#F2F2F2] tw-rounded-lg">
        <div className="tw-flex tw-items-center tw-p-3 tw-justify-between">
          <div className="tw-flex">
            <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#000]">
              Tasks
            </h5>
          </div>
          <div className="tw-flex">
            <IconCirclePlus color="#697586" size={20} />
          </div>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="tw-flex tw-items-center tw-p-3 tw-justify-between">
          <div className="tw-flex">
            <Checkbox
              defaultChecked
              color="lime.4"
              size="sm"
            />
            <div className="tw-ml-[20px]">
              <h5 className="tw-text-[14px] tw-font-medium tw-text-[#344054]">
                Finish Appointment
              </h5>
              <h5 className="tw-text-[15px] tw-text-[#475467] tw-mt-1 tw-font-normal">
                Quam sed mi pharetra dapibus pharetra dapibus
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tasks;
