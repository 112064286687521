/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconDots, IconMail, IconPhoneCall, IconVideo } from '@tabler/icons-react'
import React from 'react'

const Header = (props: any) => {
  return (
    <>
      <div className='tw-flex tw-pb-10 tw-justify-between xs:tw-flex-wrap sm:tw-flex-wrap md:tw-flex-wrap'>
          <div className='tw-flex tw-items-center xs:tw-mb-3'>
            <div className='img-container'>
              <img className="tw-w-20 tw-h-20 tw-rounded-full xs:tw-max-w-[50px] xs:tw-h-[50px]" src="../../img/profile.png" alt="Rounded avatar" />
            </div>
            <div>
              <h3 className='tw-text-3xl xs:tw-text-[20px] tw-font-semibold tw-ml-4 tw-text-[#101828]'>{props?.patientResource?.name?.[0].given?.[0]} {props?.patientResource?.name?.[0].family}</h3>
              <p className='tw-text-[16px] xs:tw-text-[12px] tw-font-normal tw-ml-4 tw-text-[#475467]'>{props?.patientResource?.telecom?.find((item: { system: string }) => item.system === 'email')?.value} |  {props?.patientResource?.telecom?.find((item: { system: string }) => item.system === 'phone')?.value}</p>
            </div>
          </div>
          <div className='tw-flex tw-gap-4 tw-items-center'>
            <div className='tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-2 right_card tw-cursor-pointer'>
              <IconMail size={24} stroke={1.5} color='#344054' />
            </div>
            <div className='tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-2 right_card tw-cursor-pointer'>
              <IconPhoneCall size={24} stroke={1.5} color='#344054' />
            </div>
            <div className='tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-2 right_card tw-cursor-pointer'>
              <IconVideo size={24} stroke={1.5} color='#344054' />
            </div>
            <div className='tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-2 right_card tw-cursor-pointer'>
              <IconDots size={24} stroke={1.5} color='#344054' />
            </div>
          </div>
      </div>
    </>
  )
}

export default Header
