/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { IconChevronLeft, IconChevronRight, IconClockHour5, IconPhone } from '@tabler/icons-react';
import { Tooltip } from '@mantine/core';

const PatientCard: React.FC = () => {
  const patients = [
    { name: 'Mildred Price', test: 'Kidney Function Test', time: '09:00 - 9:45 am' },
    { name: 'Mildred Pricessss', test: 'Kidney Function Test', time: '09:00 - 9:45 am' },
  ];

  const [currentPatientIndex, setCurrentPatientIndex] = useState(0);

  const handleNext = () => {
    setCurrentPatientIndex((prevIndex) => (prevIndex + 1) % patients.length);
  };

  const handlePrev = () => {
    setCurrentPatientIndex((prevIndex) => (prevIndex - 1 + patients.length) % patients.length);
  };

  return (
    <>
      <div className="tw-mt-6 tw-block tw-w-full tw-p-2 tw-pb-4 tw-bg-white tw-border border-[#F2F2F2] tw-rounded-lg">
        <div className="tw-flex tw-items-center tw-p-3 tw-justify-between">
          <div className="tw-flex">
            <h5 className="tw-text-[14px] tw-font-semibold tw-text-[#000]">
              Next Patient
            </h5>
          </div>
          <div className="tw-flex tw-gap-2">
            <Tooltip label="Previous" color="gray">
              <IconChevronLeft onClick={handlePrev} color="#B1B6B8" size={16} className="tw-cursor-pointer" />
            </Tooltip>
            <Tooltip label="Next" color="gray">
              <IconChevronRight onClick={handleNext} color="#B1B6B8" size={16} className="tw-cursor-pointer" />
            </Tooltip>
          </div>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="tw-relative tw-overflow-hidden tw-w-full">
          <div className="tw-flex tw-transition-transform tw-duration-500" style={{ transform: `translateX(-${currentPatientIndex * 100}%)` }}>
            {patients.map((patient, index) => (
              <div key={index} className="tw-flex-shrink-0 tw-w-full">
                <div className="tw-flex tw-items-center tw-p-3 tw-justify-between">
                  <div className="tw-flex">
                    <img className="tw-w-12 tw-h-12 tw-rounded-full" src="./img/profile.png" alt="Rounded avatar" />
                    <div className="d-grid tw-ml-[20px] tw-text-[#868686]">
                      <span className="tw-text-[14px] tw-font-semibold tw-text-[#344054]">{patient.name}</span>
                      <h5 className="tw-text-[14px] tw-font-normal tw-tracking-tight tw-text-[#475467]">
                        {patient.test}
                      </h5>
                    </div>
                  </div>
                  <div className="tw-p-2 tw-bg-[#E4F8F0] tw-rounded-full">
                    <IconPhone color="#2AAAAF" size={20} />
                  </div>
                </div>
                <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                <div className="tw-flex tw-items-center tw-p-3 tw-pt-4 tw-pb-0 tw-justify-between">
                  <div className="tw-flex tw-items-start">
                    <IconClockHour5 className="tw-mr-2" color="#3CA5A9" size={20} />
                    <span className="tw-text-[12px] tw-text-[#475467] tw-font-medium">{patient.time}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientCard;
