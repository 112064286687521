/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React from 'react'
import PatientCard from './PatientCard'
import LaboratoryCard from './LaboratoryCard'

const Provider = () => {
  return (
    <>
        <div className='tw-w-full tw-flex tw-gap-5 xs:tw-gap-0 xs:tw-flex-wrap sm:tw-flex-wrap md:tw-flex-wrap'>
          <PatientCard />
          <LaboratoryCard />
        </div>
    </>
  )
}

export default Provider
