import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { useRef } from 'react';

export function FilterButton(props: any): JSX.Element {
  const { state, setState } = props;
  const stateRef = useRef(state);
  return (
    <>
      <button
        onClick={() => setState({ ...stateRef.current, filterEditorVisible: true })}
        className="tw-flex tw-items-center tw-py-[10px] tw-px-[14px] tw-border-[1px] tw-border-[#D0D5DD] tw-rounded-[8px] tw-w-[99px] tw-justify-center tw-cursor-pointer"
      >
        <IconAdjustmentsHorizontal size={16} />
        <div className="tw-text-[#344054] tw-text-sm tw-font-semibold tw-ml-1">Filters</div>
      </button>
    </>
  );
}
