/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { useState } from "react";

export default function Carousel(slides: any) {
  const [current, setCurrent] = useState(0);

  const previousSlide = () => {
    if (current === 0) {
        setCurrent(slides?.slides.length - 1);
    } else { 
        setCurrent(current - 1);
    }
  };

  const nextSlide = () => {
    if (current === slides?.slides.length - 1) {
        setCurrent(0);
    }
    else {
        setCurrent(current + 1);
    }
  };

  return (
    <div className="tw-overflow-hidden tw-relative tw-h-full">
      <div
        className={`tw-flex tw-transition tw-ease-out tw-duration-40 tw-h-full`}
        style={{
          transform: `translateX(-${current * 100}%)`,
        }}
      >
        {slides?.slides.map((s: any, index: number) => {
          return <>
          <div key={index} className="carousel-bg" style={{ backgroundImage: `url(${s?.image})`, borderRadius: '80px 0 0 80px', placeContent: 'center', padding: '40px'}}>
            <p className="" style={{ fontSize: '30px' }}>
                {s?.text}
            </p>
          </div>
        </>;
        })}
      </div>
      <div className="tw-flex justify-center">
        <div className="car_content">
            <p style={{ fontSize: '25px'}}>Amélie Laurent</p>
            <p style={{ fontSize: '20px'}}>Lead Designer, Layers</p>
        </div>
        <div className="tw-absolute tw-top-[34%] tw-right-0 tw-h-full tw-items-center tw-flex tw-text-white tw-px-10 text-3xl">
            <button onClick={previousSlide} className="icon-bg">
            <IconArrowLeft />
            </button>
            <button onClick={nextSlide} className="icon-bg">
            <IconArrowRight />
            </button>
        </div>
      </div>

      <div className="tw-absolute tw-bottom-0 tw-py-4 tw-flex tw-justify-center tw-gap-3 tw-w-full">
        {slides?.slides.map((s: any, i: any) => {
          return (
            <div
              onClick={() => {
                setCurrent(i);
              }}
              key={"circle" + i}
              className={`rounded-full w-5 h-5 cursor-pointer  ${
                i === current ? "bg-white" : "bg-gray-500"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}