/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const checkInputValue = (name: string, value: string) => {
    const nonAlphabeticFields = ['name', 'email', 'address', 'phone', 'npi', 'brandMobile', 'brandEmail','password', 'confirm_password', 'postalCode', 'providerMobile', 'providerEmail', 'street', 'city', 'state', 'service', 'firstName', 'lastName', 'summary', 'first_name', 'last_name', 'suiteApt', 'timezone', 'insurance', 'mobile', 'insuranceNumber', 'startTime', 'endTime'];
    const limitedLengthFields = ['phone', 'brandMobile', 'providerMobile', 'npi', 'postalCode', 'mobile'];
    const numericFields = ['phone', 'brandMobile', 'providerMobile', 'npi', 'postalCode', 'price', 'insuranceNumber', 'mobile'];

    const isAlphabetic = /^[a-zA-Z\s]*$/.test(value);
    const isNumeric = /^[0-9]*$/.test(value);

    if (!isAlphabetic && !nonAlphabeticFields.includes(name)) {
        return null; // Ignore non-alphabetic input
    }

    if (numericFields.includes(name) && !isNumeric) {
        return null; // Ignore non-numeric input for numeric fields
    }

    if (limitedLengthFields.includes(name) && value.length > 10) {
        return value.slice(0, 10);
    }

    return value;
}