import { Title } from '@mantine/core';
import { Logo, SignInForm } from '@medplum/react';
import { useNavigate } from 'react-router-dom';
import Carousel from '../components/carousel.component';

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const slides = [
    { image: './img/banner-01.png', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
    { image: 'https://wallpapercave.com/wp/wp3386769.jpg', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
  ];
  return (
    <div className="tw-flex tw-min-h-screen tw-sm:block">
      <div className='tw-flex-initial tw-w-2/4 banner-bg-2 tw-grid tw-items-center'>
        <div className="tw-w-2/3">
          <Logo size={180} />
        </div>
        <SignInForm
          // Configure according to your settings
          googleClientId=""
          onForgotPassword={() => navigate('/resetpassword')}
          onSuccess={() => navigate('/Dashboard')}
        >
          <Title>Sign In</Title>
        </SignInForm>
      </div>
      <div className="tw-w-1/2 banner-bg-1">
          <Carousel slides={slides} />
      </div>
    </div>
  );
}
