/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import cardJson from './cardData.json';
import { IconArrowUp } from '@tabler/icons-react';

const FeaturedCards = () => {
  return (
    <>
      <div className="tw-flex tw-gap-4 xs:tw-gap-0 tw-flex-nowrap lg:tw-flex-nowrap sm:tw-flex-wrap xs:tw-flex-wrap">
        {cardJson.map((card, index) => {
          return (
            <div
              className="tw-mt-6 xs:tw-mt-2 tw-p-5 tw-block tw-w-full tw-bg-white tw-border border-[#F2F2F2] tw-rounded-[12px] featured-card"
              key={index}
            >
              <div className='tw-flex tw-justify-between tw-items-center tw-mb-1'>
                <h5 className="tw-text-[14px] tw-font-medium tw-tracking-tight tw-text-[#475467]  tw-pb-0">
                  {card.title}
                </h5>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <span className="tw-font-bold tw-text-black-800 tw-text-[26px]">{card.count}</span>
                <span
                  className="tw-text-black-800 tw-text-xs tw-font-medium tw-px-2 tw-py-1.5 tw-rounded-full dark:tw-bg-blue-900 dark:tw-text-blue-300 tw-border tw-border-[#ABEFC6]"
                  style={{
                    backgroundColor: '#E4F8F0'
                  }}
                >
                  <span
                    className="tw-font-bold tw-pr-1 tw-flex tw-items-center"
                    style={{
                      color: '#49997F'
                    }}
                  >
                    <IconArrowUp size={14}/>{card.percent}
                  </span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FeaturedCards;
