export interface LogoProps {
  readonly size: number;
  readonly fill?: string;
}

export function Logo(props: LogoProps): JSX.Element {
  return (
    <img src="../img/logo-vertical-navy.png" style={{width: props.size}} alt='Healthside Logo' />
  );
}
