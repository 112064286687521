/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from './axios';
import { MedplumClient } from '@medplum/core';

export const listingPage = async (medplum: MedplumClient, apiUrl: string, count?: any, offset?: any, search?: any) => {
    const token = await medplum.getAccessToken();
    let queryParameters = '';
    let sortingParameters = '';
    let status = '';
    let statusParam = '';
    // Loop through the filters array to build the query string
    if (search?.filters) {
      search.filters.forEach((filter: { code: any; value: any }) => {
        queryParameters += `&${filter.code}=${filter.value}`;
      });
    }
    if (search?.sortRules) {
      search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
        if (filter.descending) {
          sortingParameters += `&_sort=-${filter.code}`;
        } else {
          sortingParameters += `&_sort=${filter.code}`;
        }
      });
    }
    
    if(location.pathname === '/WaitingList') {
      status = 'waitlist';
    } else {
      status = 'any';
    }

    if(location.pathname === '/WaitingList') {
      statusParam = `&status=${status}`;
    }
    const response = await axios.get(`/healthside/${apiUrl}?_count=${count}&_offset=${offset}${sortingParameters}&_sort=-_lastUpdated&_total=accurate${queryParameters}${statusParam}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
};

export const getSlots = async (medplum: MedplumClient, serviceId: string, startDate: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(`/fhir/R4/Slot?_count=20&_offset=0&_sort=start&_total=accurate&schedule.actor=HealthcareService/${serviceId}&status=free&&start=ge${startDate}T00:00:00Z&start=lt${startDate}T23:59:00Z`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const bookAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/book-appointment`, payload,  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const updateAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/update-appointment`, payload,  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const cancelAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/cancel-appointment`, payload,  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const createUpdatePatient = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
  return response.data;
}

export const createUpdatePatientResource = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patientdata-resource`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
  return response.data;
}

export const createUpdatePatientAccount = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient-account`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
  return response.data;
}

export const getAppointment = async (medplum: MedplumClient, patientId: string,) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(`fhir/R4/Appointment?_count=20&_offset=0&_sort=-_lastUpdated&_total=accurate&patient=Patient/${patientId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};